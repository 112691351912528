// player does handle click events from keyboard, linter doesn't recognize this
import React from 'react';
import classNames from 'classnames';
import { GridStack } from 'components/GridStack';
import styles from './styles.module.scss';
import { TeaseImage } from './TeaseImage';
/**
 * @typedef {object} ImageProps
 * @property {string} ImageProps.src background image source url
 * @property {number | undefined} [ImageProps.height] Image height
 * @property {number | undefined} [ImageProps.width] Image width
 * @property {string} ImageProps.alt Image altText
 * @property {boolean} [ImageProps.disableLazyLoad] false by default.  Set to `true` if lazy loading
 * is interfering with largest contentful paint metric
 */

/**
 * @typedef {object} SlateButtonProps
 * @property {() => void} SlateButtonProps.onClick Fires when a user clicks the slate
 * @property {string} [SlateButtonProps.href] If set, slate will additionally function as a link
 * @property {string} SlateButtonProps.teaseImageUrl the image to use as the background
 * @property {string} SlateButtonProps.teaseImageAltText the alt text for the background image
 */

/**
 * @param {() => void} onClick
 */
export function createButtonEvents(onClick) {
  return {
    /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
    handleClick(e) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    /** @type {import('react').KeyboardEventHandler<HTMLDivElement>} */
    handleKeyDown(e) {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }
    },
  };
}


/**
 * Slate with a play button
 * @param {SlateButtonProps} props
 */
export function PlayButtonSlate({
  onClick, teaseImageUrl, teaseImageAltText,
}) {
  const { handleClick, handleKeyDown } = createButtonEvents(onClick);

  return (
    <GridStack className={classNames('videoSlate', styles.videoSlate)}>
      <TeaseImage src={teaseImageUrl} altText={teaseImageAltText} />
      <div
        role="button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label="Play"
        tabIndex={0}
        className="w-100 h-100 db"
      >
        <PlayIcon />
      </div>
    </GridStack>
  );
}

/**
 *
 */
function PlayIcon() {
  return (
    <div
      className="playButtonOuter absolute absolute-fill w100 dn-print"
      data-testid="video-player__play-button__outer"
    >
      <div
        className={classNames('absolute absolute-fill w100 df', {
          'items-center items-center-m justify-center justify-center-m': true,
          'items-center justify-center': true,
        })}
      >
        <div className={styles.play}>
          <svg
            className={styles.playIcon}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            preserveAspectRatio="xMidYMid"
            style={{ maxWidth: '30px' }}
          >
            <path d="M0 0v15l15-7.41z" fillRule="evenodd" />
          </svg>
        </div>
      </div>
    </div>
  );
}
