import React from 'react';

/**
 * @typedef {object} SlateButtonProps
 * @property {() => void} SlateButtonProps.onClick Fires when a user clicks the slate
 * @property {string} [SlateButtonProps.href] If set, slate will additionally function as a link
 */

/**
 * Slate with a play button
 * @param {object} props
 * @param {() => void} props.onClick Fires when a user clicks the replay button
 */
export function ReplayOverlay({ onClick }) {
  return (
    <button type="button" onClick={onClick} aria-label="Replay">
      <span className="replayIcon z-2 white icon icon-replay" />
    </button>
  );
}
