import React from 'react';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import { isResizableImage } from 'lib/imageUtils';
import { Picture } from 'components/Picture';

/**
 * Slate Tease Background Image
 * @param {object} props - Component properties
 * @param {string} props.src - Image URL
 * @param {string} props.altText - Image alt text
 */
export function TeaseImage({ src, altText }) {
  // If resizeable (AIMS or Telemundo) image, use Picture component.
  const pictureProps = {
    url: src,
    alt: altText,
  };
  if (isResizableImage(src)) {
    Object.assign(pictureProps, {
      responsiveFlavors: {
        s: AIMS_FLAVORS.FOCAL_760x428, // 16x9 ratio (56.25%)
        m: AIMS_FLAVORS.FOCAL_1000x563,
        xl: AIMS_FLAVORS.FOCAL_860x484,
      },
      useSuffix: true,
      aimsOptions: { mode: 5 },
    });
  }
  return <Picture {...pictureProps} />;
}
