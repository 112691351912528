/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VideoPlayer from 'components/VideoPlayer';

import { loadVideosByIds as loadVideosByIdsAction } from 'redux/modules/video/associatedPlaylists';

import Breakpoints from 'lib/Breakpoints';
import BTE from 'lib/BTE';
import {
  autoplayCapabilities as autoplayCapabilitiesPropType,
  video as videoPropType,
} from 'lib/CustomPropTypes';
import getNavbarHeight from 'lib/getNavbarHeight';
import { stub as $t } from '@nbcnews/analytics-framework';

import './styles.themed.scss';
import { VideoPlayerContext } from 'lib/ContextTypes/videoPlayer';
import { withDisplayVideoDate } from 'components/Gamma/DisplayVideoDateSwitch';

const block = 'inline-video';

/**
 *
 * @param {object} args
 * @param {object} args.shared
 * @param {object} args.router
 */
const mapStateToProps = ({
  shared, router,
}) => ({
  vertical: shared.vertical,
  domain: router.domain,
});

const mapActionsToProps = {
  loadRecommendations: loadVideosByIdsAction,
};

@connect(mapStateToProps, mapActionsToProps)
class InlineVideo extends Component {
  static contextType = VideoPlayerContext;

  static propTypes = {
    addClassNames: PropTypes.string,
    autoPlay: PropTypes.bool,
    autoplayCapabilities: autoplayCapabilitiesPropType,
    isEmbedded: PropTypes.bool,
    isHero: PropTypes.bool,
    mute: PropTypes.bool,
    onlyAutoplayMuted: PropTypes.bool,
    vertical: PropTypes.string.isRequired,
    video: videoPropType.isRequired,
    loadingLazy: PropTypes.bool,
    disableSticky: PropTypes.bool,
    disablePlaylistAutoplay: PropTypes.bool,
    pipAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
    pipAlignMobile: PropTypes.oneOf(['top', 'bottom']),
    isArticleVideoDateEnabled: PropTypes.bool,
    elementId: PropTypes.string,
  };

  static contextTypes = {
    isLiveBlog: PropTypes.bool,
  };

  static defaultProps = {
    addClassNames: '',
    autoPlay: false,
    autoplayCapabilities: null,
    isEmbedded: false,
    isHero: false,
    mute: false,
    onlyAutoplayMuted: false,
    loadingLazy: null,
    disableSticky: null,
    disablePlaylistAutoplay: false,
    pipAlignDesktop: 'bottom',
    pipAlignMobile: 'bottom',
    isArticleVideoDateEnabled: false,
    elementId: '',
  };

  /**
  *
  * @param {object} props
  * @param {string} props.addClassNames
  * @param {boolean} props.autoPlay
  * @param {object} props.autoplayCapabilities
  * @param {boolean} props.isEmbedded
  * @param {boolean} props.isHero
  * @param {boolean} props.mute
  * @param {boolean} props.onlyAutoplayMuted
  * @param {string} props.vertical
  * @param {Video} props.video
  * @param {boolean} props.loadingLazy
  * @param {boolean} props.disableSticky
  * @param {boolean} props.disablePlaylistAutoplay
  * @param {('top'|'bottom')} props.pipAlignDesktop
  * @param {('top'|'bottom')} props.pipAlignMobile
  * @param {boolean} props.isArticleVideoDateEnabled
  * @param {string} props.elementId
   */
  constructor(props) {
    super(props);

    const { video } = this.props;

    this.state = {
      associatedPlaylist: video?.associatedVideoPlaylist,
      infoHidden: false,
      adPlaying: false,
      nowPlaying: video,
      videoPlaying: false,
      videoMuted: false,
      isMounted: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.initAnalytics();

    if (this.isDefaultInlineVideo(this.props)) {
      BTE.on('scroll', this.monitorDefaultInlineVideos, 'debounce');
    }
    // This `isMounted` logic is to trigger render after mount on front end.
    // Due to relying on cookies, hydration will not work if the server
    // has a different cookie value than the client (which why wouldn't it?)
    // NOTE: for hydration the server should render exactly what the client
    // will render on first pass.
    this.setState({ isMounted: true });
  }

  /**
   *
   */
  componentWillUnmount() {
    BTE.remove('scroll', this.monitorDefaultInlineVideos, 'debounce');
  }

  /**
   *
   */
  onAdPlay = () => {
    this.setState({
      infoHidden: true,
      adPlaying: true,
    });
  }

  /**
   *
   */
  onAdEnd = () => {
    this.setState({
      infoHidden: true,
      adPlaying: false,
    });
  }

  /**
   *
   */
  onVideoPlay = () => {
    this.setState({
      videoPlaying: true,
      infoHidden: true,
    });
  }

  /**
   *
   */
  onVideoPause = () => {
    this.setState({
      videoPlaying: false,
      infoHidden: false,
    });
  }

  /**
   *
   * @param {video} selected
   */
  onVideoEnd = (selected) => {
    this.setState({
      nowPlaying: selected,
    });
  }

  /**
   *
   */
  onVideoMute = () => {
    this.setState({
      videoMuted: true,
    });
  }

  /**
   *
   */
  onVideoUnmute = () => {
    this.setState({
      videoMuted: false,
    });
  }

  /**
   *
   */
  onMouseMove = () => {
    const { infoHidden, videoPlaying } = this.state;
    if (videoPlaying) {
      if (this.isCaptionsCardOpen() && !infoHidden) {
        this.setState({ infoHidden: true });
      } else if (!this.isCaptionsCardOpen() && infoHidden) {
        this.setState({ infoHidden: false });
      }
    }
  }

  /**
   *
   */
  onMouseLeave = () => {
    const { videoPlaying } = this.state;
    if (videoPlaying) {
      this.setState({ infoHidden: true });
    }
  }

  /**
   *
   */
  isDefaultInlineVideo = () => {
    const { isEmbedded, isHero } = this.props;
    return isEmbedded === false && isHero === false;
  }

  /**
   *
   */
  initAnalytics = () => {
    if (typeof $t !== 'undefined') {
      $t('register', ['inlineVideoFullyInView']);
    }
  }

  /**
   *
   */
  trackInlineVideoFullyInView = () => {
    if (typeof $t !== 'undefined' && !this.fired) {
      $t('track', 'inlineVideoFullyInView');

      this.fired = true;
    }
  }

  /**
   *
   */
  monitorDefaultInlineVideos = () => {
    if (!this.wrapper) {
      return;
    }
    const depth = window.pageYOffset;
    const navBarHeight = getNavbarHeight(Breakpoints);
    const playerWrapper = this.wrapper;
    const { top: containerTop, height: containerHeight } = playerWrapper.getBoundingClientRect();

    const topOffset = containerTop + depth;
    const bottomOffset = topOffset + containerHeight;

    const vidContainerTopShowing = topOffset > (depth + navBarHeight);
    const vidContainerBottomShowing = bottomOffset < (depth + window.innerHeight);

    if (vidContainerTopShowing && vidContainerBottomShowing) {
      this.trackInlineVideoFullyInView();
    }
  }

  /**
   *
   */
  isCaptionsCardOpen = () => {
    if (this.player?.wrapper) {
      const captionCard = document.querySelector('.ndpCaptionOptionsCard', this.player.wrapper);
      if (captionCard && (!captionCard.style.display || captionCard.style.display !== 'none')) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * @param {video} e
   */
  play = (e) => {
    const { player } = this;
    if (player?.manualPlay) {
      player.manualPlay(e);
    }
  }

  /**
   *
   */
  render() {
    const {
      associatedPlaylist,
      nowPlaying: video,
      isMounted,
    } = this.state;

    if (!isMounted || !video) return null;

    const {
      addClassNames,
      autoPlay,
      autoplayCapabilities,
      isEmbedded,
      isHero,
      mute,
      onlyAutoplayMuted,
      vertical,
      loadingLazy,
      disableSticky,
      pipAlignDesktop,
      pipAlignMobile,
      isArticleVideoDateEnabled,
      disablePlaylistAutoplay,
      elementId,
    } = this.props;

    const { isLiveBlog } = this.context;

    const embeddedStyles = classNames(
      block,
      {
        [`${block}--in-body`]: !isEmbedded && !isHero && !isLiveBlog,
      },
      addClassNames,
    );

    const isDisableSticky = disableSticky ?? (isLiveBlog && !isHero);

    const playlist = disablePlaylistAutoplay
      ? {
        ...associatedPlaylist,
        videos: [associatedPlaylist.videos?.find((v) => v.id === video.id)],
      }
      : associatedPlaylist;

    return (
      <section
        className={embeddedStyles}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        data-testid={`${block}`}
        id={elementId ? `anchor-${elementId}` : undefined}
      >
        <div className={`${block}__background`}>
          <div
            className={`${block}__player`}
            ref={(ref) => { this.wrapper = ref; }}
          >
            <VideoPlayer
              adPlayButtonPosition="bottomLeft"
              autoPlay={autoPlay || false}
              autoplayCapabilities={autoplayCapabilities}
              continuousPlay={!disablePlaylistAutoplay}
              disableSticky={isDisableSticky}
              isEmbedded={isEmbedded}
              mute={mute || false}
              onAdEnd={this.onAdEnd}
              onAdPlay={this.onAdPlay}
              onlyAutoplayMuted={onlyAutoplayMuted}
              onRef={(player) => { this.player = player; }}
              onVideoEnd={this.onVideoEnd}
              onVideoMute={this.onVideoMute}
              onVideoPause={this.onVideoPause}
              onVideoPlay={this.onVideoPlay}
              onVideoUnmute={this.onVideoUnmute}
              playButtonStyle={`${block}__play-button`}
              playlist={[playlist]}
              shouldStickWhilePaused={isLiveBlog && isHero}
              vertical={vertical}
              video={video}
              loadingLazy={loadingLazy}
              pipAlignDesktop={pipAlignDesktop}
              pipAlignMobile={pipAlignMobile}
              hidePublishedDate={!isArticleVideoDateEnabled}
            />
          </div>
        </div>
      </section>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default withDisplayVideoDate(InlineVideo);
